<template>
	<div class="flex flex-col w-full max-h-screen min-h-screen overflow-hidden">
		<div class="flex items-center px-8 py-4 border-b min-h-16">
			<h2 class="text-xl font-bold tracking-tighter text-gray-800">Show/hide</h2>
		</div>
		<div class="p-8 overflow-y-auto scrolling-touch">
			<div class="flex flex-wrap -mx-4">
				<div class="flex flex-col items-start w-full px-4 mb-8 lg:w-1/2 lg:mb-12">
					<div class="flex flex-col items-start w-full mb-4 text-left">
						<h2 class="mb-4 font-medium leading-tight text-gray-500 uppercase">Accordeon</h2>
						<div class="w-full overflow-hidden border divide-y rounded">
							<button v-on:click="selectContent(1)" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white" :class="[selectedContent != 1 ? 'text-gray-500' : '']">
								<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="[selectedContent == 1 ? 'rotate-180' : '']" loading="lazy">
								<span class="flex-auto font-bold text-left">Schvalovací kolo č.2</span>
							</button>
							<div v-if="selectedContent == 1" class="px-4 py-2 space-y-2">
								<div class="flex items-center w-full">
									<div class="relative flex-shrink-0 w-10 h-10">
										<img src="https://avatarless.now.sh/gi/michal.david@ispalliance.cz?t=MD&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
										<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
											<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
										</div>
									</div>
									<span class="flex-auto block ml-4">David Michal</span>
								</div>
								<div class="flex items-center w-full">
									<div class="relative flex-shrink-0 w-10 h-10">
										<img src="https://avatarless.now.sh/gi/milan@sulc.dev?t=MS&s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
										<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
											<img src="https://i.flow.foundation/fa/s/thumbs-down/red-500" class="w-3 h-3 transform" loading="lazy">
										</div>
									</div>
									<span class="flex-auto block ml-4">Milan Šulc</span>
								</div>
								<div class="flex items-center w-full opacity-50">
									<div class="relative flex-shrink-0 w-10 h-10">
										<img src="https://avatarless.now.sh/gi/pavel.mica@tlapnet.cz?t=PM&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
									</div>
									<span class="flex-auto block ml-4">Pavel Míča</span>
								</div>
                            </div>
							<button v-on:click="selectContent(2)" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white" :class="[selectedContent != 2 ? 'text-gray-500' : '']">
								<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="[selectedContent == 2 ? 'rotate-180' : '']" loading="lazy">
								<span class="flex-auto font-bold text-left">Schvalovací kolo č.2</span>
							</button>
							<div v-if="selectedContent == 2" class="px-4 py-2 space-y-2">
								<div class="flex items-center w-full">
									<div class="relative flex-shrink-0 w-10 h-10">
										<img src="https://avatarless.now.sh/gi/michal.david@ispalliance.cz?t=MD&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
										<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
											<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
										</div>
									</div>
									<span class="flex-auto block ml-4">David Michal</span>
								</div>
                            </div>
							<button v-on:click="selectContent(3)" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white" :class="[selectedContent != 3 ? 'text-gray-500' : '']">
								<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="[selectedContent == 3 ? 'rotate-180' : '']" loading="lazy">
								<span class="flex-auto font-bold text-left">Schvalovací kolo č.2</span>
							</button>
							<div v-if="selectedContent == 3" class="px-4 py-2 space-y-2">
								<div class="flex items-center w-full">
									<div class="relative flex-shrink-0 w-10 h-10">
										<img src="https://avatarless.now.sh/gi/pavel.mica@tlapnet.cz?t=PM&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
										<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
											<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
										</div>
									</div>
									<span class="flex-auto block ml-4">Pavel Míča</span>
								</div>
                            </div>
						</div>
					</div>
					<div class="w-full min-w-0 overflow-hidden border rounded lotus-richtext">
						<xcode :code="codes.showHide1.code" :class="'h-128'"/>
					</div>
				</div>
				<div class="flex flex-col items-start w-full px-4 mb-8 lg:w-1/2 lg:mb-12">
					<div class="flex flex-col items-start w-full mb-4 text-left">
						<h2 class="mb-4 font-medium leading-tight text-gray-500 uppercase">Toggle multiple</h2>
						<div class="justify-end hidden w-full">
							<button class="underline hover:no-underline">
								<span @click="triggerShowAll()" v-if="showAll">Show all</span>
								<span @click="triggerHideAll()" v-if="!showAll">Hide All</span>
							</button>
						</div>
						<div class="w-full overflow-hidden border divide-y rounded">
							<button v-on:click="content1 = !content1" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white" :class="[content1 ? '' : 'text-gray-500']">
								<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="[content1 ? 'rotate-180' : '']" loading="lazy">
								<span class="flex-auto font-bold text-left">Schvalovací kolo č.2</span>
							</button>
							<div v-if="content1" class="px-4 py-2 space-y-2">
								<div class="flex items-center w-full">
									<div class="relative flex-shrink-0 w-10 h-10">
										<img src="https://avatarless.now.sh/gi/michal.david@ispalliance.cz?t=MD&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
										<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
											<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
										</div>
									</div>
									<span class="flex-auto block ml-4">David Michal</span>
								</div>
								<div class="flex items-center w-full">
									<div class="relative flex-shrink-0 w-10 h-10">
										<img src="https://avatarless.now.sh/gi/milan@sulc.dev?t=MS&s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
										<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
											<img src="https://i.flow.foundation/fa/s/thumbs-down/red-500" class="w-3 h-3 transform" loading="lazy">
										</div>
									</div>
									<span class="flex-auto block ml-4">Milan Šulc</span>
								</div>
								<div class="flex items-center w-full opacity-50">
									<div class="relative flex-shrink-0 w-10 h-10">
										<img src="https://avatarless.now.sh/gi/pavel.mica@tlapnet.cz?t=PM&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
									</div>
									<span class="flex-auto block ml-4">Pavel Míča</span>
								</div>
                            </div>
							<button v-on:click="content2 = !content2" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white">
								<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="[content2 ? 'rotate-180' : '']" loading="lazy">
								<span class="flex-auto font-bold text-left" :class="[content2 ? '' : 'text-gray-500']">Schvalovací kolo č.2</span>
							</button>
							<div v-if="content2" class="px-4 py-2 space-y-2">
								<div class="flex items-center w-full">
									<div class="relative flex-shrink-0 w-10 h-10">
										<img src="https://avatarless.now.sh/gi/michal.david@ispalliance.cz?t=MD&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
										<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
											<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
										</div>
									</div>
									<span class="flex-auto block ml-4">David Michal</span>
								</div>
                            </div>
							<button v-on:click="content3 = !content3" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white">
								<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="[content3 ? 'rotate-180' : '']" loading="lazy">
								<span class="flex-auto font-bold text-left" :class="[content3 ? '' : 'text-gray-500']">Schvalovací kolo č.2</span>
							</button>
							<div v-if="content3" class="px-4 py-2 space-y-2">
								<div class="flex items-center w-full">
									<div class="relative flex-shrink-0 w-10 h-10">
										<img src="https://avatarless.now.sh/gi/pavel.mica@tlapnet.cz?t=PM&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
										<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
											<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
										</div>
									</div>
									<span class="flex-auto block ml-4">Pavel Míča</span>
								</div>
                            </div>
						</div>
					</div>
					<div class="w-full min-w-0 overflow-hidden border rounded lotus-richtext">
						<xcode :code="codes.showHide2.code" :class="'h-128'"/>
					</div>
				</div>
				<div class="flex flex-col items-start w-full px-4 mb-8 lg:w-1/2 lg:mb-12">
					<div class="flex flex-col items-start w-full mb-4 text-left">
						<h2 class="mb-4 font-medium leading-tight text-gray-500 uppercase">Toggle single</h2>
						<div class="w-full overflow-hidden border rounded">
							<button v-on:click="content4 = !content4" class="flex items-center justify-start w-full px-4 py-2 focus:outline-none">
								<div class="flex flex-col flex-1 min-w-0 text-left">
									<div class="w-full min-w-0 truncate">Daniel Kalivoda (#2578945)</div>
									<div class="w-full min-w-0 text-gray-500 truncate">danek.kalivoda@gmail.com | 775 070605</div>
								</div>
								<img src="https://i.flow.foundation/fa/s/chevron-down/gray-500" class="flex-shrink-0 w-3 h-3 ml-4 transform" :class="[content4 ? 'rotate-180' : '']" loading="lazy">
							</button>
							<div v-if="content4" class="px-4 py-2">
								Content
                            </div>
						</div>
					</div>
					<div class="w-full min-w-0 overflow-hidden border rounded lotus-richtext">
						<xcode :code="codes.showHide3.code" :class="'h-64'"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Xcode from "~/components/Code.vue";
	import * as codes from "~/resources/codes/showHide.ts";
	export default {
		components: {
			Xcode,
		},
		data: () => ({
			selectedContent: 1,
			content1: false,
			content2: false,
			content3: false,
			content4: false,
			showAll: true,
			codes
		}),
		methods: {
			selectContent(id){
				this.selectedContent = id
			},
			triggerShowAll(){
				this.content1 = true
				this.content2 = true
				this.content3 = true
				this.showAll = false
			},
			triggerHideAll(){
				this.content1 = false
				this.content2 = false
				this.content3 = false
				this.showAll = true
			}
		}
	};
</script>
