export const DROPDOWN1 = {
	title: 'Basic menu',
	code: `
<div data-lotus-dropdown data-tippy-placement="bottom-end" class="relative inline-block">
	<button class="form-select" type="button">
		Actions
	</button>
	<div data-lotus-dropdown-target class="hidden ml-4">
		<div class="w-56 bg-white border rounded shadow-lg text-black mt-px">
			<ul class="w-full py-2">
				<li>
					<a class="block px-4 py-2 hover:bg-green-500 hover:text-white" href="#">Item 1</a>
				</li>
				<li>
					<a class="block px-4 py-2 hover:bg-green-500 hover:text-white" href="#">Item 2</a>
				</li>
				<li class="border-b text-sm text-gray-500 my-2 font-medium">
					<div class="block px-4 py-2">Section divider</div>
				</li>
				<li>
					<a class="block px-4 py-2 hover:bg-green-500 hover:text-white" href="#">Item 6</a>
				</li>
			</ul>
		</div>
	</div>
</div>
`
};

export const DROPDOWN2 = {
	title: 'Button icon - direction bottom-end',
	code: `
<div data-lotus-dropdown data-tippy-placement="bottom-end" class="relative inline-block">
	<button class="transition-all relative bg-gray-200 hover:bg-gray-300 p-1 rounded text-gray-600 w-10 h-10 hidden sm:flex flex-col items-center justify-center focus:outline-none" type="button">
		<img src="https://i.flow.foundation/fa/s/ellipsis-v/gray-600" class="h-4 w-4" loading="lazy">
	</button>
	<div data-lotus-dropdown-target class="hidden ml-4">
		<div class="w-64 px-4 py-2 bg-white border shadow-lg m-px rounded z-50 transform-t right-0 -ml-px py-2 text-black">
			This is my <strong>dropdown content</strong>. It can be any html...
		</div>
	</div>
</div>
`
};

export const DROPDOWN3 = {
	title: 'Without border - direction top-start',
	code: `
<div data-lotus-dropdown data-tippy-placement="top-start" class="relative inline-block">
	<button class="form-select border-none pl-0 underline hover:no-underline" type="button">
		Show more
	</button>
	<div data-lotus-dropdown-target class="hidden ml-4">
		<div class="w-56 bg-white border rounded shadow-lg text-black mt-px">
			<ul class="w-full py-2">
				<li>
					<a class="block px-4 py-2 hover:bg-green-500 hover:text-white" href="#">Item 1</a>
				</li>
				<li>
					<a class="block px-4 py-2 hover:bg-green-500 hover:text-white" href="#">Item 2</a>
				</li>
				<li>
					<a class="block px-4 py-2 hover:bg-green-500 hover:text-white" href="#">Item 3</a>
				</li>
			</ul>
		</div>
	</div>
</div>
`
};

export const DROPDOWN4 = {
	title: 'Label with user avatar - direction top-center, with offset',
	code: `
<div data-lotus-dropdown data-tippy-placement="top" class="relative inline-block">
	<div class="border inline-flex items-center leading-4 py-1 px-3 rounded-full mb-1 cursor-pointer">
		<img src="https://avatarless.now.sh/gi/michal.david@ispalliance.cz?t=MD&amp;s=24" alt="David Michal" class="rounded-full h-6 w-6 mr-2 -ml-2">
		<span class="min-w-0 flex-1 truncate">David Michal</span>
	</div>
	<div data-lotus-dropdown-target class="hidden">
		<div class="w-64 px-4 py-2 bg-white border shadow-lg m-4 rounded z-50 py-2 text-black flex items-center">
			<img src="https://avatarless.now.sh/gi/michal.david@ispalliance.cz?t=MD&amp;s=128" alt="David Michal" class="h-24 w-24 -ml-2" loading="lazy">
			<div class="text-lg text-gray-900 ml-2">David Michal</div>
		</div>
	</div>
</div>
`
};
