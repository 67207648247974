<template>
	<pre><code class="w-full"><template v-if="$slots.default"><slot></slot></template><template v-else>{{code.trim()}}</template></code></pre>
</template>
<script>

	export default {
		props: {
			code: {
				type: String,
				default: "",
			}
		}
	};
</script>
