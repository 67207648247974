export const LAYOUT1 = {
	title: 'Layout with sidebar and fixed top section',
	image: 'layout-1',
	code: `
process:
	name: "Use-case: layout theme a zanorene sekce"
	description: "Ukazka markdown editoru"
	version: 0.0.1

acl:
	roles:
		@creator: [2,3,4,5,6,7,8,9]

steps:
	start:
		title: Start
		type: START
		expiration: +2 hour
		acl: {resolver: @creator}
		transitions:
			finish:
		layout:
			extends: minimal
			render:
				theme:
					type: fixed
			sections:
				s1:
					render:
						display: true
						theme:
							wrapper: md:items-stretch md:flex-no-wrap
							content: md:items-stretch md:flex-no-wrap

					sections:
						s10:
							render:
								theme:
									wrapper: md:flex-1 md:flex-col bg-white md:flex-no-wrap md:items-stretch
									content: md:flex-1 md:flex-col md:h-full md:flex-no-wrap md:overflow-hidden

								display: true

							sections:
								s20:
									render:
										theme:
											wrapper: md:flex-shrink-0

									sections:
										s30:
											render:
												theme:
													wrapper: flex-col w-full max-w-4xl mx-auto

											panels:
												templater__201:
													config:
														template: "<div class=\"text-xl xl:text-2xl\">Templater as header</div>"

													render:
														theme:
															border: false
															wrapper: bg-transparent

								s40:
									render:
										theme:
											wrapper: md:flex-shrink-0 border-b bg-gray-100

									sections:
										s50:
											render:
												theme:
													wrapper: w-full max-w-4xl mx-auto
													content: flex-col md:flex-row

											panels:
												templater__302:
													config:
														template: This is the first panel in this section.

													render:
														theme:
															border: false
															wrapper: bg-gray-200 md:flex-1

												templater__303:
													config:
														template: This is the second panel in this section.

													render:
														theme:
															border: false
															wrapper: bg-gray-300 md:flex-shrink-0

								s60:
									render:
										theme:
											wrapper: md:flex-1 border-b md:border-0 md:overflow-hidden
											content: md:h-full md:overflow-y-auto

									sections:
										s70:
											render:
												theme:
													wrapper: flex-col w-full max-w-4xl mx-auto
													content: flex-col

											panels:
												templater__401:
													config:
														template: "This is the first panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

												templater__402:
													config:
														template: "This is the second panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

												templater__403:
													config:
														template: "This is the third panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

												templater__404:
													config:
														template: "This is the fourth panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

						s80:
							render:
								theme:
									wrapper: flex-col w-full max-w-4xl mx-auto md:w-80 md:border-l
									content: flex-col flex-no-wrap items-stretch md:flex-1 md:overflow-y-auto

								display: true

							panels:
								templater__101:
									config:
										template: "This is the first panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

									render:
										theme:
											wrapper: bg-transparent
											border: false

								templater__102:
									config:
										template: "This is the second panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

									render:
										theme:
											wrapper: bg-transparent
											border: false

								templater__103:
									config:
										template: "This is the third panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

									render:
										theme:
											wrapper: bg-transparent
											border: false

							sections: []

					panels: []
	finish:
		title: Konec
		type: END
		expiration: +1 week
		acl: {resolver: @creator}

variables:
	text:
		title: Text
		type: scalar

`
};

export const LAYOUT2 = {
	title: 'Centered layout with fixed top sections',
	image: 'layout-2',
	code: `
process:
	name: "Use-case: layout theme a zanorene sekce"
	description: "Ukazka markdown editoru"
	version: 0.0.1

acl:
	roles:
		@creator: [2,3,4,5,6,7,8,9]

steps:
	start:
		title: Start
		type: START
		expiration: +2 hour
		acl: {resolver: @creator}
		transitions:
			finish:
		layout:
			extends: minimal
			render:
				theme:
					type: fixed
			sections:
				s1:
					render:
						display: true
						theme:
							wrapper: md:items-stretch md:flex-no-wrap
							content: md:items-stretch md:flex-no-wrap

					sections:
						s10:
							render:
								theme:
									wrapper: md:flex-1 md:flex-col bg-white md:flex-no-wrap md:items-stretch
									content: md:flex-1 md:flex-col md:h-full md:flex-no-wrap md:overflow-hidden

								display: true

							sections:
								s20:
									render:
										theme:
											wrapper: md:flex-shrink-0

									sections:
										s30:
											render:
												theme:
													wrapper: flex-col w-full max-w-4xl mx-auto

											panels:
												templater__201:
													config:
														template: "<div class=\"text-xl xl:text-2xl\">Templater as header</div>"

													render:
														theme:
															border: false
															wrapper: bg-transparent

								s40:
									render:
										theme:
											wrapper: md:flex-shrink-0 border-b bg-gray-100

									sections:
										s50:
											render:
												theme:
													wrapper: w-full max-w-4xl mx-auto
													content: flex-col md:flex-row

											panels:
												templater__302:
													config:
														template: This is the first panel in this section.

													render:
														theme:
															border: false
															wrapper: bg-gray-200 md:flex-1

												templater__303:
													config:
														template: This is the second panel in this section.

													render:
														theme:
															border: false
															wrapper: bg-gray-300 md:flex-shrink-0

								s60:
									render:
										theme:
											wrapper: md:flex-1 border-b md:border-0 md:overflow-hidden
											content: md:h-full md:overflow-y-auto

									sections:
										s70:
											render:
												theme:
													wrapper: flex-col w-full max-w-4xl mx-auto
													content: flex-col

											panels:
												templater__401:
													config:
														template: "This is the first panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

												templater__402:
													config:
														template: "This is the second panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

												templater__403:
													config:
														template: "This is the third panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

												templater__404:
													config:
														template: "This is the fourth panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

						s80:
							render:
								theme:
									wrapper: flex-col w-full max-w-4xl mx-auto md:w-80 md:border-l
									content: flex-col flex-no-wrap items-stretch md:flex-1 md:overflow-y-auto

								display: true

							panels:
								templater__101:
									config:
										template: "This is the first panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

									render:
										theme:
											wrapper: bg-transparent
											border: false

								templater__102:
									config:
										template: "This is the second panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

									render:
										theme:
											wrapper: bg-transparent
											border: false

								templater__103:
									config:
										template: "This is the third panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

									render:
										theme:
											wrapper: bg-transparent
											border: false

							sections: []

					panels: []
	finish:
		title: Konec
		type: END
		expiration: +1 week
		acl: {resolver: @creator}

variables:
	text:
		title: Text
		type: scalar

`
};
export const LAYOUT3 = {
	title: 'Layout with sidebar and fixed bottom section',
	image: 'layout-3',
	code: `
	process:
	name: "Use-case: layout theme a zanorene sekce"
	description: "Ukazka markdown editoru"
	version: 0.0.1

acl:
	roles:
		@creator: [2,3,4,5,6,7,8,9]

steps:
	start:
		title: Start
		type: START
		expiration: +2 hour
		acl: {resolver: @creator}
		transitions:
			finish:
		layout:
			extends: minimal
			render:
				theme:
					type: fixed
			sections:
				s1:
					render:
						display: true
						theme:
							wrapper: md:items-stretch md:flex-no-wrap
							content: md:items-stretch md:flex-no-wrap

					sections:
						s10:
							render:
								theme:
									wrapper: md:flex-1 md:flex-col bg-white md:flex-no-wrap md:items-stretch
									content: md:flex-1 md:flex-col md:h-full md:flex-no-wrap md:overflow-hidden

								display: true

							sections:
								s10:
									render:
										theme:
											wrapper: md:flex-1 border-b md:border-0 md:overflow-hidden
											content: md:h-full md:overflow-y-auto

									sections:
										s70:
											render:
												theme:
													wrapper: flex-col w-full max-w-4xl mx-auto
													content: flex-col

											panels:
												templater__401:
													config:
														template: "This is the first panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

												templater__402:
													config:
														template: "This is the second panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

												templater__403:
													config:
														template: "This is the third panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false

												templater__404:
													config:
														template: "This is the fourth panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

													render:
														theme:
															border: false
								s20:
									render:
										theme:
											wrapper: md:flex-shrink-0 md:border-t

									sections:
										s30:
											render:
												theme:
													wrapper: flex-col w-full max-w-4xl mx-auto

											panels:
												templater__201:
													config:
														template: 'Fixed bottom section'

													render:
														theme:
															border: false
															wrapper: bg-transparent

						s80:
							render:
								theme:
									wrapper: flex-col w-full max-w-4xl mx-auto md:w-80 md:border-l
									content: flex-col flex-no-wrap items-stretch md:flex-1 md:overflow-y-auto

								display: true

							panels:
								templater__101:
									config:
										template: "This is the first panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

									render:
										theme:
											wrapper: bg-transparent
											border: false

								templater__102:
									config:
										template: "This is the second panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

									render:
										theme:
											wrapper: bg-transparent
											border: false

								templater__103:
									config:
										template: "This is the third panel in this section. <div class='text-xs mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a finibus enim. Ut vestibulum sit amet diam eu dictum. Vestibulum tincidunt, felis a pretium lobortis, orci enim rutrum diam, sed luctus nulla eros at diam. Quisque euismod consequat felis, non dapibus mi accumsan vel</div>"

									render:
										theme:
											wrapper: bg-transparent
											border: false

							sections: []

					panels: []
	finish:
		title: Konec
		type: END
		expiration: +1 week
		acl: {resolver: @creator}

variables:
	text:
		title: Text
		type: scalar

`
};
