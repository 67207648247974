export const showHide1 = {
	code: `
<div class="w-full overflow-hidden border divide-y rounded" x-data="alpineAccordeon()">
	<button @click="selectContent(1)" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white" :class="[selectedContent != 1 ? 'text-gray-500' : '']">
		<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="[selectedContent == 1 ? 'rotate-180' : '']" loading="lazy">
		<span class="flex-auto font-bold text-left">Schvalovací kolo č.2</span>
	</button>
	<div class="px-4 py-2 space-y-2" :class="[selectedContent == 1 ? 'block' : 'hidden']" x-cloak>
		<div class="flex items-center w-full">
			<div class="relative flex-shrink-0 w-10 h-10">
					<img src="https://avatarless.now.sh/gi/michal.david@ispalliance.cz?t=MD&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
					<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
						<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
					</div>
			</div>
			<span class="flex-auto block ml-4">David Michal</span>
		</div>
		<div class="flex items-center w-full">
			<div class="relative flex-shrink-0 w-10 h-10">
				<img src="https://avatarless.now.sh/gi/milan@sulc.dev?t=MS&s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
				<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
					<img src="https://i.flow.foundation/fa/s/thumbs-down/red-500" class="w-3 h-3 transform" loading="lazy">
				</div>
			</div>
			<span class="flex-auto block ml-4">Milan Šulc</span>
		</div>
		<div class="flex items-center w-full opacity-50">
			<div class="relative flex-shrink-0 w-10 h-10">
				<img src="https://avatarless.now.sh/gi/pavel.mica@tlapnet.cz?t=PM&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
			</div>
			<span class="flex-auto block ml-4">Pavel Míča</span>
		</div>
	</div>
	<button @click="selectContent(2)" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white" :class="[selectedContent != 2 ? 'text-gray-500' : '']">
		<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="[selectedContent == 2 ? 'rotate-180' : '']" loading="lazy">
		<span class="flex-auto font-bold text-left">Schvalovací kolo č.2</span>
	</button>
	<div class="px-4 py-2 space-y-2" :class="[selectedContent == 2 ? 'block' : 'hidden']" x-cloak>
		<div class="flex items-center w-full">
			<div class="relative flex-shrink-0 w-10 h-10">
				<img src="https://avatarless.now.sh/gi/michal.david@ispalliance.cz?t=MD&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
				<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
					<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
				</div>
			</div>
			<span class="flex-auto block ml-4">David Michal</span>
		</div>
	</div>
	<button @click="selectContent(3)" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white" :class="[selectedContent != 3 ? 'text-gray-500' : '']">
		<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="[selectedContent == 3 ? 'rotate-180' : '']" loading="lazy">
		<span class="flex-auto font-bold text-left">Schvalovací kolo č.2</span>
	</button>
	<div class="px-4 py-2 space-y-2" :class="[selectedContent == 3 ? 'block' : 'hidden']" x-cloak>
		<div class="flex items-center w-full">
			<div class="relative flex-shrink-0 w-10 h-10">
				<img src="https://avatarless.now.sh/gi/pavel.mica@tlapnet.cz?t=PM&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
				<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
					<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
				</div>
			</div>
			<span class="flex-auto block ml-4">Pavel Míča</span>
		</div>
	</div>
</div>
<script>
	window.alpineAccordeon = function () {
		return {
		selectedContent: 1,
			selectContent(id){
				this.selectedContent = id
			}
		}
	}
</script>
`
};

export const showHide2 = {
	code: `
<div class="w-full overflow-hidden border divide-y rounded">
	<div x-data="{open: true}" class="divide-y">
		<button @click="open = !open" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white" :class="open ? '' : 'text-gray-500'">
			<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="open ? 'rotate-180' : ''" loading="lazy">
			<span class="flex-auto font-bold text-left">Schvalovací kolo č.2</span>
		</button>
		<div class="px-4 py-2 space-y-2" x-show="open">
			<div class="flex items-center w-full">
				<div class="relative flex-shrink-0 w-10 h-10">
				<img src="https://avatarless.now.sh/gi/michal.david@ispalliance.cz?t=MD&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
				<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
					<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
				</div>
				</div>
				<span class="flex-auto block ml-4">David Michal</span>
			</div>
			<div class="flex items-center w-full">
				<div class="relative flex-shrink-0 w-10 h-10">
				<img src="https://avatarless.now.sh/gi/milan@sulc.dev?t=MS&s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
				<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
					<img src="https://i.flow.foundation/fa/s/thumbs-down/red-500" class="w-3 h-3 transform" loading="lazy">
				</div>
				</div>
				<span class="flex-auto block ml-4">Milan Šulc</span>
			</div>
			<div class="flex items-center w-full opacity-50">
				<div class="relative flex-shrink-0 w-10 h-10">
				<img src="https://avatarless.now.sh/gi/pavel.mica@tlapnet.cz?t=PM&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
				</div>
				<span class="flex-auto block ml-4">Pavel Míča</span>
			</div>
		</div>
	</div>
	<div x-data="{open: true}" class="divide-y">
		<button @click="open = !open" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white" :class="open ? '' : 'text-gray-500'">
			<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="open ? 'rotate-180' : ''" loading="lazy">
			<span class="flex-auto font-bold text-left" :class="open ? '' : 'text-gray-500'">Schvalovací kolo č.2</span>
		</button>
		<div class="px-4 py-2 space-y-2" x-show="open" x-cloak>
			<div class="flex items-center w-full">
				<div class="relative flex-shrink-0 w-10 h-10">
				<img src="https://avatarless.now.sh/gi/michal.david@ispalliance.cz?t=MD&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
				<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
					<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
				</div>
				</div>
				<span class="flex-auto block ml-4">David Michal</span>
			</div>
		</div>
	</div>
	<div x-data="{open: true}" class="divide-y">
		<button @click="open = !open" class="flex items-center justify-start w-full px-4 py-2 bg-gray-100 focus:outline-none hover:bg-white" :class="open ? '' : 'text-gray-500'">
			<img src="https://i.flow.foundation/fa/s/chevron-down/green-500" class="flex-shrink-0 w-3 h-3 mr-4 transform" :class="[open ? 'rotate-180' : '']" loading="lazy">
			<span class="flex-auto font-bold text-left" :class="open ? '' : 'text-gray-500'">Schvalovací kolo č.2</span>
		</button>
		<div class="px-4 py-2 space-y-2" x-show="open">
			<div class="flex items-center w-full">
				<div class="relative flex-shrink-0 w-10 h-10">
					<img src="https://avatarless.now.sh/gi/pavel.mica@tlapnet.cz?t=PM&amp;s=64" alt="David Michal" class="w-full h-full rounded-full" loading="lazy">
					<div class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 -mr-2 bg-white rounded-full shadow">
						<img src="https://i.flow.foundation/fa/s/thumbs-up/green-500" class="w-3 h-3 transform" loading="lazy">
					</div>
				</div>
				<span class="flex-auto block ml-4">Pavel Míča</span>
			</div>
		</div>
	</div>
</div>
`
};
export const showHide3 = {
	code: `
<div class="w-full overflow-hidden border rounded" x-data="{open: false}">
	<button @click="open = !open" class="flex items-center justify-start w-full px-4 py-2 focus:outline-none">
		<div class="flex flex-col flex-1 min-w-0 text-left">
			<div class="w-full min-w-0 truncate">Daniel Kalivoda (#2578945)</div>
			<div class="w-full min-w-0 text-gray-500 truncate">danek.kalivoda@gmail.com | 775 070605</div>
		</div>
		<img src="https://i.flow.foundation/fa/s/chevron-down/gray-500" class="flex-shrink-0 w-3 h-3 ml-4 transform" :class="open ? 'rotate-180' : ''" loading="lazy">
	</button>
	<div x-show="open" class="px-4 py-2" x-cloak>
		Content
	</div>
</div>
`
};
