import Tippy, {PopperElement} from "tippy.js";
import "tippy.js/dist/tippy.css";
import 'tippy.js/animations/shift-toward-subtle.css';
import "tippy.js/themes/light.css";

export function initTooltip({container}: Lifecycle.Event): void {
	container.querySelectorAll<HTMLElement>("[data-lotus-tooltip]")
		.forEach((el: HTMLElement): void => {
			el.addEventListener('mouseenter', (e) => {
				setupTooltip(<HTMLElement>e.currentTarget);
			}, {once: true})
		});
}

function setupTooltip(el: HTMLElement): void {
	// Do nothing, if Tippy is already there.
	if ((<PopperElement>el)._tippy) {
		return;
	}

	if (!el.dataset.lotusTooltip) return;

	Tippy(el, {
		theme: "light",
		arrow: true,
		showOnCreate: true,
		animation: "shift-toward",
		delay: [150, 0],
		content: el.dataset.lotusTooltip,
	});
}
