export const TOOLTIP1 = {
	title: 'Text tooltip',
	code: `
<div data-lotus-tooltip="This is tooltip" class="border-b border-gray-400 border-dashed cursor-auto -mt-px relative">
	<div >text tooltip</div>
</div>
`
};

export const TOOLTIP2 = {
	title: 'Tooltip from icon',
	code: `
<div data-lotus-tooltip="This is tooltip" class="cursor-auto">
	<img src="https://i.flow.foundation/fa/s/info-circle/gray-600" class="h-5 w-5" loading="lazy">
</div>
`
};
