import VueRouter from "vue-router";

export function createRouter(): VueRouter {
	const router = new VueRouter({
		routes: [
			{path: '/', component: () => import('./view/index')},
			{
				path: '/app/',
				component: () => import('./view/layout/app'),
				children: [
					{path: '/app/layouts/', component: () => import('./view/app/layouts')},
					{path: '/app/ui-elements/', component: () => import('./view/app/ui-elements')},
					{path: '/app/page-4x/', component: () => import('./view/app/page-4x')},
					{
						path: '/app/unsupported-browser/',
						component: () => import('./view/app/unsupported-browser')
					},
					{path: '/app/datagrid/', component: () => import('./view/app/datagrid')}
				]
			},
			{
				path: '/ui/',
				component: () => import('./view/layout/ui'),
				children: [
					{path: '/ui/', component: () => import('./view/ui/index')},
					{path: '/ui/alert/', component: () => import('./view/ui/alert.vue')},
					{path: '/ui/avatar/', component: () => import('./view/ui/avatar.vue')},
					{path: '/ui/badge/', component: () => import('./view/ui/badge.vue')},
					{path: '/ui/list/', component: () => import('./view/ui/list.vue')},
					{path: '/ui/text/', component: () => import('./view/ui/text.vue')},
					{path: '/ui/color/', component: () => import('./view/ui/color.vue')},
					{path: '/ui/icon/', component: () => import('./view/ui/icon.vue')},
					{path: '/ui/tooltip/', component: () => import('./view/ui/tooltip.vue')},
					{path: '/ui/dropdown/', component: () => import('./view/ui/dropdown.vue')},
					{path: '/ui/show-hide/', component: () => import('./view/ui/showHide.vue')},
				]
			},
		]
	});

	return router;
}
