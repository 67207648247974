<template>
	<div class="flex items-stretch w-full max-h-screen min-h-screen">
		<aside class="flex flex-col w-48 bg-gray-200 xl:w-64">
			<router-link to="/" class="flex items-center py-4 pl-6 bg-white border-b group min-h-16">
				<img src="https://i.flow.foundation/fa/s/arrow-left/64/gray-500" class="w-4 h-4 mr-4 transition duration-150 ease-in-out opacity-50 group-hover:opacity-100">
				<h1 class="w-full font-medium leading-tight text-green-500 xl:text-lg">Floweye <span class="font-bold text-gray-800">Design</span></h1>
			</router-link>
			<h2 class="px-6 mt-8 text-sm font-medium leading-tight text-gray-500 uppercase">App components</h2>
			<div class="flex flex-row items-center flex-shrink px-2">
				<nav class="w-full">
					<ul class="w-full py-1 xl:py-2">
						<li>
							<router-link v-slot="{ href, navigate, isActive }" to="/app/layouts">
								<a :href="href" @click="navigate" :class="[isActive ? 'bg-green-500 text-white' : 'text-gray-800 hover:bg-white']" class="flex items-center justify-start flex-grow px-4 py-2 transition-all rounded cursor-pointer group" class="flex-grow-0">Layouts</a>
							</router-link>
						</li>
						<li>
							<router-link v-slot="{ href, navigate, isActive }" to="/app/ui-elements">
								<a :href="href" @click="navigate" :class="[isActive ? 'bg-green-500 text-white' : 'text-gray-800 hover:bg-white']" class="flex items-center justify-start flex-grow px-4 py-2 transition-all rounded cursor-pointer group" class="flex-grow-0">UI elements</a>
							</router-link>
						</li>
						<li>
							<router-link v-slot="{ href, navigate, isActive }" to="/app/page-4x">
								<a :href="href" @click="navigate" :class="[isActive ? 'bg-green-500 text-white' : 'text-gray-800 hover:bg-white']" class="flex items-center justify-start flex-grow px-4 py-2 transition-all rounded cursor-pointer group">Error page</a>
							</router-link>
						</li>
						<li>
							<router-link v-slot="{ href, navigate, isActive }" to="/app/unsupported-browser">
								<a :href="href" @click="navigate" :class="[isActive ? 'bg-green-500 text-white' : 'text-gray-800 hover:bg-white']" class="flex items-center justify-start flex-grow px-4 py-2 transition-all rounded cursor-pointer group">Unsupported browser</a>
							</router-link>
						</li>
						<li>
							<router-link v-slot="{ href, navigate, isActive }" to="/app/datagrid">
								<a :href="href" @click="navigate" :class="[isActive ? 'bg-green-500 text-white' : 'text-gray-800 hover:bg-white']" class="flex items-center justify-start flex-grow px-4 py-2 transition-all rounded cursor-pointer group">Datagrid table</a>
							</router-link>
						</li>
					</ul>
				</nav>
			</div>
		</aside>
		<div class="relative flex-1 min-w-0 bg-white">
			<div class="absolute left-0 z-10 w-px h-full bg-gray-300"></div>
			<router-view/>
		</div>
	</div>
</template>
<script>
	export default {}
</script>
