<template>
	<div class="min-h-screen max-h-screen flex flex-col w-full overflow-hidden">
		<div class="py-4 px-8 border-b min-h-16 flex items-center">
			<h2 class="text-xl font-bold text-gray-800 tracking-tighter">Tooltip</h2>
		</div>
		<div class="p-8 overflow-y-auto scrolling-touch">
			<div class="flex flex-wrap -mx-4">
				<template v-for="code in codes">
					<div class="flex flex-col w-full lg:w-1/2 mb-8 lg:mb-12 px-4 items-center lg:items-start">
						<div class="text-left flex-shrink-0 flex flex-col items-center mb-4 lg:items-start">
							<h2 class="uppercase font-medium leading-tight text-gray-500 mb-4">{{code.title}}</h2>
							<div v-html="code.code"></div>
						</div>
						<div class="w-full lotus-richtext border rounded min-w-0 overflow-hidden">
							<xcode :code="code.code" :class="'h-32'"/>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
	import {initTooltip} from "@/ui/effects/tooltip";
	import Xcode from "~/components/Code.vue";
	import * as codes from "~/resources/codes/tooltip.ts";

	export default {
		components: {
			Xcode,
		},
		data: () => ({
			codes,
		}),
		mounted() {
			initTooltip({container: this.$el});
		}
	};
</script>
