import Tippy, {PopperElement} from "tippy.js";

export function initDropdown({container}: Lifecycle.Event): void {
	// Add onclick to all
	container.querySelectorAll<HTMLElement>("[data-lotus-dropdown]")
		.forEach((toggler: HTMLElement): void => {
			toggler.removeEventListener("click", _toggleDropdown as EventListener);
			toggler.addEventListener("click", _toggleDropdown as EventListener);
		});
}

function _toggleDropdown(e: DOMEvent<HTMLElement>): void {
	e.stopPropagation();

	// Do nothing, if Tippy is already there.
	if ((<PopperElement>e.currentTarget)._tippy) {
		return;
	}

	// Variable for tippy html content
	let target: HTMLElement | null;

	if (e.currentTarget.getAttribute('data-lotus-dropdown-content') !== null) {
		// Specified target
		target = document.getElementById(<string>e.currentTarget.getAttribute('data-lotus-dropdown-content'));
	} else {
		// Inner target
		target = e.currentTarget.querySelector('[data-lotus-dropdown-target]')
	}

	if (target === null) {
		console.error('[Tippy] dropdown target not found');
		return;
	}

	const content = target.firstElementChild || target.innerHTML;

	Tippy(e.currentTarget, {
		theme: "lotusmini",
		allowHTML: true,
		interactive: true,
		delay: [0, 0],
		trigger: 'click',
		arrow: false,
		showOnCreate: true,
		placement: "bottom",
		maxWidth: 'none',
		animation: 'shift-toward-subtle',
		offset: [0, 0],
		onCreate(instance) {
			instance.popper.querySelectorAll<PopperElement>('[data-lotus-dropdown-click-hide]')
				.forEach((el: PopperElement): void => {
					el.addEventListener("click", () => {
						instance.hide();
					}, {once: true})
				});
		},
		content,
	});
}
