<template>
	<div class="flex flex-col w-full min-w-0 min-h-screen">
		<div class="flex items-center px-8 py-4 border-b min-h-16">
			<h2 class="text-xl font-bold tracking-tighter text-gray-800">Process layouts</h2>
		</div>
		<div class="w-full min-w-0 p-8 overflow-y-auto scrolling-touch">
			<div class="flex flex-wrap flex-1 min-w-0 -mx-4">
				<template v-for="code in codes">
					<div class="flex flex-col items-center flex-shrink w-full min-w-0 px-4 mb-8 sm:w-1/2 xl:w-1/3 lg:mb-12 sm:items-start">
						<div class="flex flex-col items-center flex-shrink-0 mb-4 text-left sm:items-start">
							<h2 class="mb-4 font-medium leading-tight text-gray-500 uppercase">{{code.title}}</h2>
							<div class="p-4 rounded shadow">
								<img :src="images[code.image]" loading="lazy"/>
							</div>
						</div>
						<div class="w-full min-w-0 border rounded lotus-richtext">
							<xcode :code="code.code" :class="'h-64'"/>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
	import {initDropdown} from "@/ui/effects/dropdown";
	import Xcode from "~/components/Code.vue";
	import * as codes from "~/resources/codes/layouts.ts";
	import images from '~/resources/images/*.jpg';

	export default {
		components: {
			Xcode,
		},
		data: () => ({
			codes,
			images
		})
	};
</script>
