<template><img :src="src" alt="">
</template>
<script>
	export default {
		props: {
			kind: {
				type: String,
				default: 'solid',
			},
			icon: {
				type: String,
				required: true,
			}
		},
		data: () => ({
			base: 'https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.12.1/svgs'
		}),
		computed: {
			src() {
				return `${this.base}/${this.kind}/${this.icon}.svg`;
			}
		}
	}
</script>
