import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import {createRouter} from "./router";
import Icon from './components/Icon';

// Plugins
Vue.use(VueRouter);

// Components
Vue.component('icon', Icon);

// Root app
new Vue({
	router: createRouter(),
	el: '#app',
	render: h => h(App)
});

// Hello
console.log('Hello from Lotus Design System');
